import { createApp } from 'vue';
import App from './App.vue';
// import { components } from './components';
import router from './router';
import 'virtual:svg-icons-register';
import './styles/index.scss'
import './utils/rem.js'

const app = createApp(App);

app.use(router);
app.mount('#app');

// 加载全局组件
// components.forEach((component) => {
//   app.component(component.name, component);
// });

