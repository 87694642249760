import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router';
// import {trackRouter} from 'vue-gtag-next'
// import Cookies from 'js-cookie'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/strategy',
    name: 'strategy',
    component: () => import('@/views/strategy/index.vue')
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import('@/views/detail/index.vue')
  },
  {
    path: '/SDKlist',
    name: 'SDKlist',
    component: () => import('@/views/SDKlist/index.vue')
  },
  {
    path: '/ChildrenPrivacy',
    name: 'ChildrenPrivacy',
    component: () => import('@/views/ChildrenPrivacy/index.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/agreement/index.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy/index.vue')
  },
  {
    path: '/AccountCancellation',
    name: 'AccountCancellation',
    component: () => import('@/views/AccountCancellation/index.vue')
  },
  {
    path: "/informationList",
    name: "informationList",
    component: () => import("@/views/informationList/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// trackRouter(router)

router.beforeEach((to, from, next) => {
  // @ts-ignore
  const VITE_PUBLIC_PATH = import.meta.env.VITE_PUBLIC_PATH.split('|');
  const authRequired = VITE_PUBLIC_PATH.indexOf(to.path) < 0
  // const token = Cookies.get('token')
  
  // if (authRequired && token === undefined) {
  //   return next({ name: 'login' })
  // }
  next()
})

export default router;
